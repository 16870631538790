import { useState, useEffect } from 'react';
import getSize from './size';

const useMobile = () => {
  const [isMobile, setIsMobile] = useState();

  const handleResize = () => {
    setIsMobile(getSize({ width: window.innerWidth }).isMobile);
  };

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isMobile
  };
};

export default useMobile;
